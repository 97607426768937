import { Message } from "stateful-messaging-system";

export class UrlChanged extends Message {

	location: string;
	parts: string[]
	page: string;
	lang: string;
	constructor(location: string) {
		super();
		this.location = location;

		let p = this.parseUrl(this.location);
		this.parts = p.parts;
		this.lang = p.lang;
		this.page = p.page;
	}

	private parseUrl(url: string) {
		let parts = url.split('/').filter((el) => { return el.length != 0 });
		let res = { parts, lang: null, page: null };

		if (parts.length == 0) {
			if (navigator && (navigator.language === "nl" || navigator.languages[0] === "nl" || navigator.languages[0] === "nl-BE" || navigator.languages[0] === "nl-nl")) {
				res.lang = "nl";			
			}
			else { res.lang = "en"; }
			res.page = "home";
		}
		else if (parts.length == 1) {
			res.lang = parts[0];
			res.page = "home";
		}
		else if (parts.length == 2) {
			res.lang = parts[0];
			res.page = parts[1];
		}
		else if (parts.length >= 3) {
			res.lang = parts[0];
			res.page = parts.splice(1).join("/");
		}
		return res;
	}

	isActivePage(url: string): boolean {
		let p = this.parseUrl(url)
		return this.page == p.page && this.lang == p.lang;
	}
}